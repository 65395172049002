document.addEventListener("turbo:load", function () {
  $('body').on('click', '.place-title', function () {
    let dataset = this.dataset;
    $('.leaflet-marker-icon').map(function () {
      let marker = $(this);
      if (marker.hasClass(dataset.id)) {
        marker.addClass('selected-marker');
      } else {
        marker.removeClass('selected-marker');
      }
    });
  });
});
document.addEventListener("turbo:load", function () {
  $(".story-status-form").on('change', function (event) {
    event.preventDefault();
    const id = event.currentTarget.dataset['storyId'];
      $.ajax({
        url: '/admin/stories/' + id + '/state_update',
        method: 'PATCH',
        data: { state: event.target.defaultValue }
      })
    }
  );
});

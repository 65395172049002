document.addEventListener("turbo:load", function () {
  $('#url_full').on('input', function() {
    const inputValue = $(this).val();
    const containsQuestionMark = inputValue.includes('?');
    const messageContainer = $('#message-container');
    const noRadioButton = $('#query_param_needed_false');

    if (containsQuestionMark) {
      messageContainer.show();
    } else {
      messageContainer.hide();
      noRadioButton.prop('checked', true);
    }
  });
});
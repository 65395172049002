document.addEventListener("turbo:load", function () {
  $('.complete-story-story-date-field').on('change', function() {
    let fieldsWithValues = $('.complete-story-story-date-field').filter(function () {
      return this.value != ''
    });

    if (fieldsWithValues.length == 0) {
      $('.complete-story-story-date-label').addClass('incomplete-form-item');
    } else {
      $('.complete-story-story-date-label').removeClass('incomplete-form-item');
    }
  })

  $('.complete-story-place-groupings-field').on('change', function() {
    let fieldsWithValues = $('.complete-story-place-groupings-field').filter(function () {
      return this.value != ''
    });

    if (fieldsWithValues.length == 0) {
      $('.complete-story-place-groupings-label').addClass('incomplete-form-item');
    } else {
      $('.complete-story-place-groupings-label').removeClass('incomplete-form-item');
    }
  })

  $('.complete-story-url-title-field').on('change', function() {
    let fieldsWithValues = $('.complete-story-url-title-field').filter(function () {
      return this.value != ''
    });

    if (fieldsWithValues.length == 0) {
      $('.complete-story-url-title-label').addClass('incomplete-form-item');
    } else {
      $('.complete-story-url-title-label').removeClass('incomplete-form-item');
    }
  })

  $('.complete-story-url-desc-field').on('change', function() {
    let fieldsWithValues = $('.complete-story-url-desc-field').filter(function () {
      return this.value != ''
    });

    if (fieldsWithValues.length == 0) {
      $('.complete-story-url-desc-label').addClass('incomplete-form-item');
    } else {
      $('.complete-story-url-desc-label').removeClass('incomplete-form-item');
    }
  })

  $('.complete-story-editor-tagline-field').on('change', function() {
    let fieldsWithValues = $('.complete-story-editor-tagline-field').filter(function () {
      return this.value != ''
    });

    if (fieldsWithValues.length == 0) {
      $('.complete-story-editor-tagline-label').addClass('incomplete-form-item');
    } else {
      $('.complete-story-editor-tagline-label').removeClass('incomplete-form-item');
    }
  })
});

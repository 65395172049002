document.addEventListener("turbo:load", function () {
  $('.select-user-story-place-checkbox').on('click', function (event) {
    let isChecked = $(this).is(':checked');
    const action = isChecked ? 'save' : 'forget';
    const ids = $(this).val().split(", ");
    const siblings = $(this).parent().parent().find(".select-user-story-place-checkbox");
    let chapter = $(this).parent().parent().siblings().children(".user-chapter-checkbox");
    let siblingCheck = true;
    for (const sibling of siblings) {
      const status = $(sibling).is(':checked');
      const comp = status === isChecked;
      if (comp === false) {
        siblingCheck = false;
      }
    }
    let chapterStatus = siblingCheck && isChecked
    chapter.checked = chapterStatus
    $(chapter[0]).prop('checked', chapterStatus)
    // Update class of icon
    const bookmark = $(this).parent().parent().parent().find(".saved-bookmark i");
    if (bookmark.hasClass("fas fa-bookmark")) {
      bookmark.removeClass("fas fa-bookmark").addClass("far fa-bookmark");
    } else {
      bookmark.removeClass("far fa-bookmark").addClass("fas fa-bookmark");
    }
    $.ajax({
      url: '/user_story_places/' + action,
      method: 'POST',
      dataType: 'json',
      data: { place_id: ids[0], story_id: ids[1], chapter_id: ids[2] }
    })
      .done(function (data) {
        if (!data['success']) {
          alert(data['message']);
        };
      })
  })
});
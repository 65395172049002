document.addEventListener("turbo:load", function () {
  $("#sortable-table tbody").sortable({
    handle: ".handle",
    update: updateRanks
  }).disableSelection();

  function updateRanks() {
    const businessAccountId = $(this).data("business-account-id");
    const rankingData = {};
    $("#sortable-table tbody tr").each(function(index) {
      const businessAccountStoryId = $(this).data("business-account-story-id");
      const rank = index + 1;
      rankingData[businessAccountStoryId] = rank;
    });
    $.ajax({
      url: `/admin/business_accounts/${businessAccountId}/business_stories/sortable_rank_save`,
      type: 'POST',
      dataType: 'json',
      data: { rankings: rankingData },
    })
  };
});

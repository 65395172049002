document.addEventListener("turbo:load", function () {
  // NOTE: Needed to show validation for required field
  $(".categories_search").on('chosen:ready', function(evt, params, chosen) {
	  $(this).css({'position': 'absolute', 'height': 0, 'opacity': 0}).show();
  });
  $('.categories_search').chosen({
    width: '100%',
    placeholder_text_multiple: 'Choose Categories',
  });
  $('body').on('click', '#shareButton', function () {
    let copyText = document.getElementById('shareLink');
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard
      .writeText(copyText.value)
      .then(() => {
        alert("Successfully Copied Link");
      })
      .catch(() => {
        // alert("something went wrong");
      });
  });
});
document.addEventListener("turbo:load", function () {
  $('body').on('click', '.share-button', function (e) {
    let copyText = e.target.dataset.link;

    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        alert("Successfully Copied Link");
      })
      .catch(() => {
        // alert("something went wrong");
      });
  });
});
$(document).on('turbo:load', () => {
  $(".custom-sidebar .menu-item").on('click', function(e) {
    if (e.target !== this && !$(e.target).hasClass('right'))
    return;
    $(this).children("ul").slideToggle();
    $(this).find(".right").toggleClass("fa-caret-up fa-caret-down");
    $(this).children("ul").removeClass('d-block');
  });
  $('.custom-sidebar .menu-item-active').parent('li').find(".right").toggleClass("fa-caret-up fa-caret-down");
})

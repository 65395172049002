import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    let element = this.element;
    let originalSrc = element.src;
    let srcChanged = false; // Flag to track whether source has been changed

    if (!element.dataset.sourceChanged) {
      // If the source has not already been changed
      let loadTimer = setTimeout(function () {
        // If the image loading times out and source has not changed, revert to the original source
        if (!srcChanged) {
          element.src = originalSrc;
        }
      }, 5000); // Set the timeout duration (in milliseconds)

      let src = element.dataset.source;
      element.src = src;

      // Add event listener for the image load event
      element.addEventListener('load', function () {
        // If the image loads within the specified time, clear the timer and update flag
        clearTimeout(loadTimer);
        srcChanged = true;
        element.dataset.sourceChanged = true; // Set the flag in dataset
      });

      // Add event listener for the image error event
      element.addEventListener('error', function () {
        // If there's an error loading the image, clear the timer
        clearTimeout(loadTimer);
        element.src = originalSrc;
      });
    }
  }
}

require("@rails/ujs").start()
// Turbo
import "@hotwired/turbo-rails"
// Stimulus
import '../controllers/index.js'
require("@rails/activestorage").start()
require("jquery")
require("jquery-ui-dist/jquery-ui")
require("bootstrap")
require("social-share-button")
require("chosen-js")
require("trix")
require("@rails/actiontext")

import $ from "expose-loader?exposes=$,jQuery!jquery";

// ICONS
import "@fortawesome/fontawesome-free/css/all"
// CSS
import '../stylesheets/application.scss';
// JS
import '../js/main.js';
// Images
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
// Highcharts
import Highcharts from 'highcharts';
window.Highcharts = Highcharts;

import { barChart, pieChart, lineChart } from '../js/highcharts.js';

window.barChart = barChart;
window.pieChart = pieChart;
window.lineChart = lineChart;
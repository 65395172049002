document.addEventListener("turbo:load", function () {
  $(".csv-import").on('change', '#import_type_dropdown', function (event) {
    event.preventDefault();
    let selectedVal = $(this).find(":selected").val();
    switch (selectedVal) {
      case 'places':
        $(".csv-import").find("#places").removeClass('d-none')
        $(".csv-import").find("#places_to_3rd_party_urls").addClass('d-none')
        $(".csv-import").find("#places_to_story").addClass('d-none')
        $(".csv-import").find("#place_categories").addClass('d-none')
        $(".csv-import").find("#place_connections").addClass('d-none')
        $(".csv-import").find("#stories").addClass('d-none')
        $(".csv-import").find("#videos").addClass('d-none')
      break;
      case 'places_to_3rd_party_urls':
        $(".csv-import").find("#places").addClass('d-none')
        $(".csv-import").find("#places_to_3rd_party_urls").removeClass('d-none')
        $(".csv-import").find("#places_to_story").addClass('d-none')
        $(".csv-import").find("#place_categories").addClass('d-none')
        $(".csv-import").find("#place_connections").addClass('d-none')
        $(".csv-import").find("#stories").addClass('d-none')
        $(".csv-import").find("#videos").addClass('d-none')
        break;
      case 'videos':
        $(".csv-import").find("#places").addClass('d-none')
        $(".csv-import").find("#places_to_3rd_party_urls").addClass('d-none')
        $(".csv-import").find("#places_to_story").addClass('d-none')
        $(".csv-import").find("#place_categories").addClass('d-none')
        $(".csv-import").find("#place_connections").addClass('d-none')
        $(".csv-import").find("#stories").addClass('d-none')
        $(".csv-import").find("#videos").removeClass('d-none')
        break;
      case 'places_to_story':
        $(".csv-import").find("#places").addClass('d-none')
        $(".csv-import").find("#places_to_3rd_party_urls").addClass('d-none')
        $(".csv-import").find("#places_to_story").removeClass('d-none')
        $(".csv-import").find("#place_categories").addClass('d-none')
        $(".csv-import").find("#place_connections").addClass('d-none')
        $(".csv-import").find("#stories").addClass('d-none')
        $(".csv-import").find("#videos").addClass('d-none')
        break;
        case 'place_categories':
        $(".csv-import").find("#places").addClass('d-none')
        $(".csv-import").find("#places_to_3rd_party_urls").addClass('d-none')
        $(".csv-import").find("#places_to_story").addClass('d-none')
        $(".csv-import").find("#place_categories").removeClass('d-none')
        $(".csv-import").find("#place_connections").addClass('d-none')
        $(".csv-import").find("#stories").addClass('d-none')
        $(".csv-import").find("#videos").addClass('d-none')
        break;
      case 'place_connections':
        $(".csv-import").find("#places").addClass('d-none')
        $(".csv-import").find("#places_to_3rd_party_urls").addClass('d-none')
        $(".csv-import").find("#places_to_story").addClass('d-none')
        $(".csv-import").find("#place_categories").addClass('d-none')
        $(".csv-import").find("#place_connections").removeClass('d-none')
        $(".csv-import").find("#stories").addClass('d-none')
        $(".csv-import").find("#videos").addClass('d-none')
        break;
      case 'stories':
        $(".csv-import").find("#places").addClass('d-none')
        $(".csv-import").find("#places_to_3rd_party_urls").addClass('d-none')
        $(".csv-import").find("#places_to_story").addClass('d-none')
        $(".csv-import").find("#place_categories").addClass('d-none')
        $(".csv-import").find("#place_connections").addClass('d-none')
        $(".csv-import").find("#stories").removeClass('d-none')
        $(".csv-import").find("#videos").addClass('d-none')
        break;
    }
  })
})

document.addEventListener("turbo:load", function () {
  function togglePasswordVisibility(toggleButtonId, passwordFieldId, iconId) {
    $(toggleButtonId).click(function(e) {
      e.preventDefault();
      let passwordField = $(passwordFieldId);
      let passwordFieldType = passwordField.attr('type');
      let passwordToggleIcon = $(iconId);
      
      if (passwordFieldType === 'password') {
        passwordField.attr('type', 'text');
        passwordToggleIcon.removeClass('fas fa-eye').addClass('fas fa-eye-slash');
      } else {
        passwordField.attr('type', 'password');
        passwordToggleIcon.removeClass('fas fa-eye-slash').addClass('fas fa-eye');
      }
    });
  }

  togglePasswordVisibility('#password-toggle', '#password-field', '#password-toggle-icon');
  togglePasswordVisibility('#password-toggle-edit', '#password-field-edit', '#password-toggle-icon-edit');
  togglePasswordVisibility('#password-confirmation-toggle', '#password-confirmation-field', '#password-confirmation-toggle-icon');
});
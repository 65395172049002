document.addEventListener('turbo:load', function () {
  $('body').on('click', '.favorite-collections-button', function () {
    let firstChild = $(this).children()[0];
    let secondChild = $(this).children().eq(1);
    let url;
    let savingStory = firstChild.className == 'fas fa-bookmark';
    if (savingStory) {
      url = this.dataset.saveUrl;
    } else {
      url = this.dataset.forgetUrl;
    }
    $.ajax({
      url: url,
      method: 'POST',
      contentType: 'application/json',
    }).done(function (data) {
      if (savingStory) {
        firstChild.className = 'fas fa-times';
        secondChild.html('Forget Collection');
      } else {
        firstChild.className = 'fas fa-bookmark';
        secondChild.html('Save Collection');
      }
    }).fail( function(xhr, textStatus, errorThrown) {
        alert(xhr.responseText);
    });
  });
});

import { Controller } from "@hotwired/stimulus"
import { desktopBreakpoint } from '../../config';
import { toggleDisabledOnInput, updateTextCount, instantiateMapAndRender, mapHasNotBeenInstantiated } from './index';

const mapIdentifier = 'desktopMap';

export default class extends Controller {
  // Initial page load
  connect() {
    if ($(window).width() >= desktopBreakpoint) {
      if (mapHasNotBeenInstantiated(mapIdentifier)) {
        instantiateMapAndRender(mapIdentifier);
      }

      this.updateNameCount();
      this.updateDescCount();

      if ($('.display-error').length > 0) {
        $('#my_story_place_full_desktop').trigger('focus');
        // scroll to bottom of screen to see form
        window.scrollTo(0,document.body.scrollHeight);
      }
    }
  }

  submitForm() {
    removeRedirectErrors();
    const newForm = $('#new_my_story_desktop');
    const invalidInputs = $(newForm).find('input:invalid, textarea:invalid');
    if (invalidInputs.length > 0) {
      // get first invalid input
      const invalidInput = invalidInputs[0];
      // focus on the first invalid input
      $(invalidInput).trigger('focus');
    }
  }

  updateNameCount() {
    updateTextCount('#my_story_name_desktop', '#name-max-length-desktop');
  }

  updateDescCount() {
    updateTextCount('#my_story_description_desktop', '#description-min-length-desktop', 50, 300);
  }

  placesUrlToggleDisabled() {
    toggleDisabledOnInput('#my_story_place_full_desktop');
  }
}

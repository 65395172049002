document.addEventListener('turbo:load', () => {
  $("#story-places").sortable({
    handle: '.handle',
    update: function(e, ui) {
      $.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize')
      })
    }
  });
});

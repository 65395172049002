import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.modal = this.element
    document.addEventListener("click", this.handleOutsideClick.bind(this))
    // add styles to the modal
    this.expandAndCenterModal()
  }

  disconnect() {
    document.removeEventListener("click", this.handleOutsideClick.bind(this))
  }

  open() {
    console.log('open')
    // using javascript to open the modal with css.
    this.modal.style.display = 'block'
    this.modal._isShown = true
  }

  close(event) {
    console.log('close')
    // using javascript to close the modal with css.
    this.modal.style.display = 'none'
    this.modal._isShown = false
  }

  expandAndCenterModal() {
    // Get the parent element of the modal
    const parentRect = this.modal.parentElement
    // make as wide as the parent element
    this.modal.style.position = 'absolute'
    // make sure it's on top
    this.modal.style.zIndex = 1000
    // add padding
    this.modal.style.margin = '10px'
    this.modal.style.width = '95%'
  }

  handleOutsideClick(event) {
    console.log('handleOutsideClick')
    const modal = this.modal;
    const rect = modal.getBoundingClientRect();

    // Check if the click event's coordinates are within the bounding box of the modal
    // Hack to check if the click event was on an input element
    // This prevents the modal from closing when clicking on an input element
    const isInput = event.srcElement.tagName === 'INPUT'

    const isInsideModal = (
      (event.clientX >= rect.left &&
      event.clientX <= rect.right &&
      event.clientY >= rect.top &&
      event.clientY <= rect.bottom) || isInput
    );

    if (isInsideModal || isInput) {
      console.log("Click is inside the modal.");
    } else {
      console.log("Click is outside the modal.");
      this.close()
    }
  }
}

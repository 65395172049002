document.addEventListener("turbo:load", function () {
  $("body").on('change', '.connection-type', function() {
    let selectedVal = $(this).find(":selected").val();
    let id = this.id; // This is a permalink
    let saveStoryWrapper = $(".save-place-link-" + id);

    $.ajax({
      url: '/places/' + id + '/save',
      method: 'POST',
      dataType:'json',
      data: { interest: selectedVal },
    })
      .done(function (data) {
        if (data['success']) {
          saveStoryWrapper.html(data['message']);
        } else {
          alert(data['message']);
          saveStoryWrapper.html(data['message']);
        };
      })
  })
});

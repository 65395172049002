import { Controller } from "@hotwired/stimulus"
import EsriMap from '../esri_map';


// Connects to data-controller="group-map"
export default class extends Controller {
  connect() {
    const mapInstance = new EsriMap('places-map-group');
    const map = mapInstance.map;

    let marker = {};

    let markers = L.featureGroup();

    getPlacesAndRender();

    function getPlacesAndRender(options = {}) {
      let getUrl = window.location;
      let isAdminMap = getUrl.href.includes('admin');
      let params_with_admin_flag = [`admin=${isAdminMap}`].join('&');
      let endpoint = $('#places-map-group').data('url');
      let baseUrl =
        getUrl.protocol +
        '//' +
        getUrl.host +
        endpoint +
        '&' +
        `${params_with_admin_flag}`;

      $.ajax({
        url: baseUrl,
        method: 'GET',
        contentType: 'application/json',
        dataType: 'json',
      }).done(function (data) {
        if (data['places']) {
          let places = data['places'];
          $('.places-count').text(places.length);
          if (places.length > 0) {
            map.fitBounds(
              places.map((place) => {
                return [place['latitude'], place['longitude']];
              }),
              { padding: [10, 10] }
            );
            places.forEach((place) => {
              addPinToMap(
                { latitude: place['latitude'], longitude: place['longitude'] },
                place['link'],
                place['id']
              );
            });
          }
        } else if (data['error']) {
          window.location = '/';
        }
      });
    }

    function addPinToMap(pin, link, id) {
      let lat = Math.round(pin.latitude * 1000000) / 1000000;
      let lon = Math.round(pin.longitude * 1000000) / 1000000;

      marker = L.marker([lat, lon]);
      markers.addLayer(marker);
      map.addLayer(markers);
      if (marker._icon){
        marker._icon.classList.add(id);
      }
      marker.on('click', function (e) {
        loadPlaceDetail(id);
        applySelectedMarkerLogic(e.target);
      });
    }

    function loadPlaceDetail(id) {
      // Locate the link element
      const link = document.getElementById(id)

      // Trigger the click programmatically if the link exists
      if (link) {
        link.click()
      }
    }

    function applySelectedMarkerLogic(marker) {
      $('.leaflet-marker-icon').map(function () {
        $(this).removeClass('selected-marker');
      });
      if (!L.DomUtil.hasClass(marker._icon, 'selected-marker')) {
        L.DomUtil.addClass(marker._icon, 'selected-marker');
      }
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// This is a controller for reusable actions to be used throughout the application
export default class extends Controller {
  shareLink(event) {
    const clickedElement = event.target; // This will be the icon that was clicked
    const shareLink = $(clickedElement).parents().find('.share-link').data('link');

    // Copy the link to the clipboard
    navigator.clipboard.writeText(shareLink).then(() => {
      alert("Successfully Copied Link");
    }).catch(() => {
      // alert("something went wrong");
    });
  }
}

const getCellValue = (tr, index) => tr.children[index].innerText || tr.children[index].textContent;

const comparer = (index, asc) => (a, b) => ((v1, v2) =>
  v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
  )(getCellValue(asc ? a : b, index), getCellValue(asc ? b : a, index));

$(document).on('turbo:load', () => {
  document.querySelectorAll('.sort-by').forEach(th => th.addEventListener('click', (() => {
    const table = th.closest('table');

    Array.from(table.querySelectorAll('tr:nth-child(n+2)'))
        .sort(comparer(Array.from(th.parentNode.children).indexOf(th), this.asc = !this.asc))
        .forEach(tr => table.appendChild(tr) );
  })));
})

document.addEventListener("turbo:load", function () {
  $("body").on('click', '.add-place-link', function (event) {
    event.preventDefault();
    let placeId = this.id;
    let storyId = $(this).parent().attr('id');

    $.ajax({
      url: '/admin/stories/' + storyId + '/add_place' + `?place_id=${placeId}`,
      method: 'POST',
      data: { place_id: placeId },
    });
  })

  $('.story-chapter-selection').on('change', function() {
    let chapterId = this.value;
    let storyId = $(this).parent().attr('id');
    let url = '/admin/stories/' + storyId + '/select_chapter' + `?chapter_id=${chapterId}`;

    window.location.assign(url);
  })

  $("#add-placeholder-button").on('click', function(e){
    $("#add-placeholder").collapse("toggle");
  });

  $("#chapters").sortable({
    handle: '.handle',
    update: function(e, ui) {
      $.ajax({
        url: $(this).data("url"),
        type: "PATCH",
        data: $(this).sortable('serialize')
      })
    }
  });
});

document.addEventListener('turbo:load', function () {
  updatePlaceButtonLinks();
  $('#connections-types-dropdown').on('input', function () {
    if ($(this).val() == 'not_contained') {
      $('.search-filters').hide();
    } else {
      $('.search-filters').show();
    }
    updatePlaceButtonLinks();
  });

  function updatePlaceButtonLinks() {
    $('.add-place-button').each(function (i, obj) {
      let url = obj.action;
      let connection_type = $('#connections-types-dropdown').val();
      if (url.includes('connection_type')) {
        let split_url = url.split('connection_type=')[0]
        obj.action = split_url + `connection_type=${connection_type}`;
      } else {
        obj.action = url + '&connection_type=' + connection_type;
      }
    });
  }
});
